<template>

  <section class="market-wrapper">

    <div class="market-header d-flex justify-content-between p-2 " data-toggle="collapse" v-bind:data-target="dataTarget" aria-expanded="false" v-bind:aria-controls="ariaControls">
      <div v-text="market_name"  ></div>
      <div class="text-light">
        <i class="bi bi-chevron-down"></i>
      </div>
    </div>

    <div v-if="parseInt(outcome.market_id) === 18">
      <div class="markets-odd-section collapse " :class="shouldCollapse(outcome.market_id)" v-bind:id="ariaControls">
      <div class="p-0 m-0 row" v-for="(o,index) in totals_18" :key="getKey(index)">
        <div class="col p-0">
        <Odd
            v-bind:sport_id="fixture.sport_id"
            v-bind:market_name="o.market_name"
            v-bind:market_id="o.market_id"
            v-bind:home_team="fixture.competitor_1"
            v-bind:away_team="fixture.competitor_2"
            v-bind:odd_id="o.under.odd_id"
            v-bind:show_direction="show_direction"
            v-bind:match_id="fixture.match_id"
            v-bind:outcome_id="o.under.outcome_id"
            v-bind:specifier="o.under.specifier"
            v-bind:active="o.under.active"
            v-bind:status="o.under.status"
            v-bind:odds="o.under.odd"
            v-bind:probability="o.under.probability"
            v-bind:producer_id="o.under.producer_id"
            v-bind:producer_status="o.under.producer_status"
            v-bind:previous_odds="o.under.previous_odds"
            v-bind:alias="o.under.alias"
            v-bind:pick="o.under"/>
        </div>
        <div class="col p-0">
          <Odd
              v-bind:sport_id="fixture.sport_id"
              v-bind:market_name="o.market_name"
              v-bind:market_id="o.market_id"
              v-bind:home_team="fixture.competitor_1"
              v-bind:away_team="fixture.competitor_2"
              v-bind:odd_id="o.over.odd_id"
              v-bind:show_direction="show_direction"
              v-bind:match_id="fixture.match_id"
              v-bind:outcome_id="o.over.outcome_id"
              v-bind:specifier="o.over.specifier"
              v-bind:active="o.over.active"
              v-bind:status="o.over.status"
              v-bind:odds="o.over.odd"
              v-bind:probability="o.over.probability"
              v-bind:producer_id="o.over.producer_id"
              v-bind:producer_status="o.over.producer_status"
              v-bind:previous_odds="o.over.previous_odds"
              v-bind:alias="o.over.alias"
              v-bind:pick="o.over"/>
        </div>
      </div>
      </div>
    </div>

    <div v-else-if="parseInt(outcome.market_id) === 238">
      <div class="markets-odd-section collapse " :class="shouldCollapse(outcome.market_id)" v-bind:id="ariaControls">
        <div class="p-0 m-0 row" v-for="(o,index) in totals_238" :key="getKey(index)">

          <div class="col p-0">
            <Odd
                v-bind:sport_id="fixture.sport_id"
                v-bind:market_name="o.market_name"
                v-bind:market_id="o.market_id"
                v-bind:home_team="fixture.competitor_1"
                v-bind:away_team="fixture.competitor_2"
                v-bind:odd_id="o.under.odd_id"
                v-bind:show_direction="show_direction"
                v-bind:match_id="fixture.match_id"
                v-bind:outcome_id="o.under.outcome_id"
                v-bind:specifier="o.under.specifier"
                v-bind:active="o.under.active"
                v-bind:status="o.under.status"
                v-bind:odds="o.under.odd"
                v-bind:probability="o.under.probability"
                v-bind:producer_id="o.under.producer_id"
                v-bind:producer_status="o.under.producer_status"
                v-bind:previous_odds="o.under.previous_odds"
                v-bind:alias="o.under.alias"
                v-bind:pick="o.under"/>
          </div>

          <div class="col p-0">
            <Odd
                v-bind:sport_id="fixture.sport_id"
                v-bind:market_name="o.market_name"
                v-bind:market_id="o.market_id"
                v-bind:home_team="fixture.competitor_1"
                v-bind:away_team="fixture.competitor_2"
                v-bind:odd_id="o.over.odd_id"
                v-bind:show_direction="show_direction"
                v-bind:match_id="fixture.match_id"
                v-bind:outcome_id="o.over.outcome_id"
                v-bind:specifier="o.over.specifier"
                v-bind:active="o.over.active"
                v-bind:status="o.over.status"
                v-bind:odds="o.over.odd"
                v-bind:probability="o.over.probability"
                v-bind:producer_id="o.over.producer_id"
                v-bind:producer_status="o.over.producer_status"
                v-bind:previous_odds="o.over.previous_odds"
                v-bind:alias="o.over.alias"
                v-bind:pick="o.over"/>
          </div>

        </div>
      </div>
    </div>

    <div v-else-if="parseInt(outcome.market_id) === 166">
      <div class="markets-odd-section collapse " :class="shouldCollapse(outcome.market_id)" v-bind:id="ariaControls">
        <div class="p-0 m-0 row" v-for="(o,index) in totals_166" :key="getKey(index)">

          <div class="col p-0">
            <Odd
                v-bind:sport_id="fixture.sport_id"
                v-bind:market_name="o.market_name"
                v-bind:market_id="o.market_id"
                v-bind:home_team="fixture.competitor_1"
                v-bind:away_team="fixture.competitor_2"
                v-bind:odd_id="o.under.odd_id"
                v-bind:show_direction="show_direction"
                v-bind:match_id="fixture.match_id"
                v-bind:outcome_id="o.under.outcome_id"
                v-bind:specifier="o.under.specifier"
                v-bind:active="o.under.active"
                v-bind:status="o.under.status"
                v-bind:odds="o.under.odd"
                v-bind:probability="o.under.probability"
                v-bind:producer_id="o.under.producer_id"
                v-bind:producer_status="o.under.producer_status"
                v-bind:previous_odds="o.under.previous_odds"
                v-bind:alias="o.under.alias"
                v-bind:pick="o.under"/>
          </div>

          <div class="col p-0">
            <Odd
                v-bind:sport_id="fixture.sport_id"
                v-bind:market_name="o.market_name"
                v-bind:market_id="o.market_id"
                v-bind:home_team="fixture.competitor_1"
                v-bind:away_team="fixture.competitor_2"
                v-bind:odd_id="o.over.odd_id"
                v-bind:show_direction="show_direction"
                v-bind:match_id="fixture.match_id"
                v-bind:outcome_id="o.over.outcome_id"
                v-bind:specifier="o.over.specifier"
                v-bind:active="o.over.active"
                v-bind:status="o.over.status"
                v-bind:odds="o.over.odd"
                v-bind:probability="o.over.probability"
                v-bind:producer_id="o.over.producer_id"
                v-bind:producer_status="o.over.producer_status"
                v-bind:previous_odds="o.over.previous_odds"
                v-bind:alias="o.over.alias"
                v-bind:pick="o.over"/>
          </div>

        </div>
      </div>
    </div>

    <div v-else>
      <div class="markets-odd-section collapse" :class="shouldCollapse(outcome.market_id)" v-bind:id="ariaControls">
        <div class="p-0 m-0 row">
          <div v-bind:class="columnCount" class="p-0" v-for="(o,index) in outcome.outcome" :key="getKey(index)">
            <Odd
                v-bind:sport_id="fixture.sport_id"
                v-bind:market_name="outcome.market_name"
                v-bind:home_team="fixture.competitor_1"
                v-bind:away_team="fixture.competitor_2"
                v-bind:pick="o"
                v-bind:show_direction="show_direction"
                v-bind:match_id="fixture.match_id"
                v-bind:market_id="market_id"
                v-bind:outcome_id="o.outcome_id"
                v-bind:probability="o.probability"
                v-bind:specifier="o.specifier"
                v-bind:active="o.active"
                v-bind:status="o.status"
                v-bind:odds="o.odd"
                v-bind:alias="o.alias"
                v-bind:producer_id="o.producer_id"
                v-bind:producer_status="o.producer_status"
                v-bind:previous_odds="o.previous_odds"/>
          </div>
        </div>
      </div>
    </div>

  </section>

</template>

<script>

import Odd from './ExtendedOdd'

export default {
  components: {
    Odd
  },
  name: "Outcome",
  props: {
    outcome: {
      type: Object,
      required: true
    },
    market_id: {
      required: true
    },
    market_name: {
      required: true
    },
    totals_238: {
      //type: Array,
      required: true
    },
    totals_18: {
      //type: Array,
      required: true
    },
    totals_166: {
      //type: Array,
      required: true
    },
    fixture: {
      type: Object,
      required: true
    },
  },
  methods: {
    shouldCollapse: function(market_id){
      console.log(market_id);
      var highPriority = [1,10,11,29,18,12,13];
      if(highPriority.indexOf(parseInt(market_id)) != -1 || market_id == "" || market_id == undefined)
      {
        return ' show';
        // element found
      }else{
        return '';
      }
    },
    isLocked: function(o){

      if(parseInt(o.producer_id) === 1 ) {

        return  parseInt(o.status) > 0 || parseInt(o.active) === 0 || parseInt(o.producer_status) === 0;

      } else {

        return  parseInt(o.status) > 0 || parseInt(o.active) === 0;

      }
    },
    getKey: function(index){

      return Math.random().toString(10).replace('0.', 'specifiers-id-' +index+ '-');

    },
    /**
     *
     * @param sport_id
     * @param match_id
     * @param market_name
     * @param market_id
     * @param competitor_1
     * @param competitor_2
     * @param picks
     */
    pick: function (sport_id, match_id, market_name, market_id, competitor_1, competitor_2, picks) {

      var vm = this;

      console.log('addPick match_id=>'+match_id+' market_name =>'+market_name+' market_id=>'+market_id+' picks=>'+JSON.stringify(picks));

      var odd_id = picks.odd_id;
      var bSlip = this.getObject("bslip");

      if (!Array.isArray(bSlip)) {

        bSlip = [];
      }

      var isunselect = false;
      picks.match_id = match_id;
      picks.sport_id = sport_id;
      picks.market_name = market_name;
      picks.market_id = market_id;
      picks.competitor_1 = competitor_1;
      picks.competitor_2 = competitor_2;

      if (!bSlip || bSlip === '' || bSlip === "") {

        bSlip = [];
      }

      if (bSlip.length > 0) {

        vm.jQuery.each(bSlip, function (k, v) {

          if (v !== undefined && v !== null) {

            if (v.odd_id === odd_id) {

              isunselect = true;
            }

            if (v.match_id === match_id) {

              delete bSlip[k];
            }
          }
        });
      }

      if (!isunselect) {

        bSlip.push(picks);
      }

      var bt = [];

      vm.jQuery.each(bSlip, function (k, v) {

        if (v !== undefined && v) {

          bt.push(v);
        }

      });

      this.saveObject("bslip", bt);
      this.autoRefreshUI(this.$vnode.tag);

    },
    getOddDirectionClass: function (outcome) {

      console.log(JSON.stringify(outcome,undefined,2))

      if(parseInt(outcome.active) === 0 || parseInt(outcome.status) !== 0) {

        return '';
      }

      if(parseFloat(outcome.odd) >= parseFloat(outcome.previous_odds) ) {

        return 'arrow green up';

      }

      return 'arrow red down';

    },

    getClasses: function (o) {

      //var st = parseInt(o.active) === 0 || parseInt(o.status) !== 0 ? 'locked btn-odd' : 'btn-odd';

      return "btn-odd small-txt mr-1 mt-1 " +
          " match-"+this.fixture.match_id +
          " producer-" + o.producer_id +
          " market-" + this.market_id +
          " outcome-" + o.outcome_id +
          " specifier-" + o.specifier;

    },
    getStatusClass: function (){

      //this.statusClass = this.locked ? 'lock' : '';

    }

  },
  mounted: function () {

    var vm = this;

    //console.log("outcome ==>"+JSON.stringify(vm.outcome));

    this.EventBus.$on('odds:'+vm.id,function(payload){

      vm.oddStatus = payload.status;
      vm.oddActive = payload.active;

      if(parseFloat(payload.odd) !== parseFloat(vm.odd)) {

        vm.previousOdds = vm.odd;
        vm.odd = payload.odd;
      }

      vm.locked = parseInt(vm.oddStatus) > 0 || parseInt(vm.oddActive) === 0 || parseInt(vm.producerStatus) === 0;
      //vm.getStatusClass();

      vm.directionClass = vm.getOddDirectionClass();

    });

    this.EventBus.$on('producer:status',function(payload){

      var producer_id = payload.producer_id;
      var producer_status = payload.producer_status;

      if(parseInt(producer_id) === 1 ) {

        vm.producerStatus = producer_status;
        vm.locked = parseInt(vm.oddStatus) > 0 || parseInt(vm.oddActive) === 0 || parseInt(vm.producerStatus) === 0;
        vm.getStatusClass();
      }

    });
  },
  data: function (){
    return {
      show_direction: false
    }
  },
  computed: {

    dataTarget: function (){

      return "#collapseThreeway"+this.market_id;
    },
    ariaControls: function (){

      return "collapseThreeway"+this.market_id;
    },
    columnCount: function (){

      var x = this.outcome.outcome.length;

      if (x === 3 ) {

        return "col-4";
      }

      return "col-6";

    }
  }
}
</script>