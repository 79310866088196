import { render, staticRenderFns } from "./ShimmerMarkets.vue?vue&type=template&id=ff1c39b4&scoped=true"
import script from "./ShimmerMarkets.vue?vue&type=script&lang=js"
export * from "./ShimmerMarkets.vue?vue&type=script&lang=js"
import style0 from "./ShimmerMarkets.vue?vue&type=style&index=0&id=ff1c39b4&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ff1c39b4",
  null
  
)

export default component.exports